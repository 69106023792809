import { Container, Grid, Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CheckList from "../components/CheckList";
import EnquiryForm from "../components/EnquiryForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  bridgingLoanPageHeroContainer: {
    backgroundColor: orange[50]
  },
  bridgingLoanPageTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const BridgingLoanPage = () => {
  const classes = useStyles();
  const title = "Bridging Loan Quote";
  const description =
    "Our expert advisers can help bridge the gap in your projects funding, in the time, and at a rate you require.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.bridgingLoanPageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography
                className={classes.bridgingLoanPageTitle}
                variant="h1"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <CheckList
                points={[
                  "Money in as little as 7 days",
                  "Access to over 200 lenders",
                  "No upfront fees"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <EnquiryForm
                type="BRIDGING_LOAN"
                steps={[
                  "Your Details",
                  "Business Details",
                  "Property Details",
                  "Mortgage Details"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default BridgingLoanPage;
